import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_GET_SpecDiscountData, API_POST_SpecDiscountData, API_PUT_SpecDiscountData, API_PUT_DiscVisible, API_POST_ContinuingStudentSet } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
function TraineeDiscount() {
    var history = useHistory();
    const [dataList, setDataList] = useState([{}]);
    useEffect(() => {
        getData();

    }, []);

    function getData() {
        API_GET_SpecDiscountData({})
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setDataList(data.result);
                }
                else if (data.result == null) {
                    setDataList(function (prev) {
                        return prev.filter((x, i) => i != 0);
                    });
                    alert(data.message);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [type, settype] = useState(0);
    function add() {
        settype(2);
        setDataList(function (prev) {
            return [...prev, { SpecDiscID: null, SpecIsDef: 0, SpecNo: null, SpecName: null, SpecDiscType: null, SpecDeptID: null, isVisiable: false, isEdit: true }];
        });
    }
    function Edit(type, index) {
        
        if (type == 2 && dataList[index].id == null) {
            //按下的是新增
            //呼叫API新增
            API_POST_SpecDiscountData({
                SpecName: dataList[index].SpecName,
                SpecDiscType: dataList[index].SpecDiscType,
                SpecDiscMoney: dataList[index].SpecDiscMoney,
                isVisiable: dataList[index].isVisiable
            })
                .then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        window.location = "/";
                    }
                    if (data.code == "0000") {
                        alert("新增成功");
                        Edit(0, i);
                    } else if (data.code == "2013") {
                        //特定的失敗原因
                    } else {
                        setMsgStr(data.message);
                    }

                    //console.log(localStorage.getItem('menulist'));
                })
                .catch((err) => {
                    if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        else if (type == 1) {
            console.log(type);
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        if (item.isEdit == true) {
                            API_PUT_SpecDiscountData({
                                SpecDiscID: dataList[index].SpecDiscID,
                                SpecName: dataList[index].SpecName,
                                SpecDiscType: dataList[index].SpecDiscType,
                                SpecDiscMoney: dataList[index].SpecDiscMoney,
                                isVisiable: Boolean(dataList[index].isVisiable)
                            })
                                .then((response) => response.data)
                                .then((data) => {
                                    if (!checkAuthToken(data)) {
                                        window.location = "/";
                                    }
                                    if (data.code == "0000") {
                                        alert("修改完成");
                                        Edit(0, i);
                                    } else if (data.code == "2013") {
                                        //特定的失敗原因
                                    } else {
                                        setMsgStr(data.message);
                                    }
                                })
                                .catch((err) => {
                                    if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                                        localStorage.clear();
                                        history.push("/");
                                    }
                             });
                        }
                        item.isEdit = type == 1 ? true : false;

                    }
                    return item;
                });
            });
        }
        else {
            settype(0);
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        if (item.isEdit == true) {
                            item.isEdit = false;
                            if (item.SpecDiscID == null) {
                                getData();
                            }

                        }
                    }
                    return item;
                });
            });
        }
    }
    function delData() {
        //呼叫API

        //成功
        setDataList(function (prev) {
            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }
    function MLink() {
       
            location.href = "Main?path=QuestionMaintain";
   
    }
    function RLink() {
        location.href = "Main?path=QuestionResult";

    }
    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalEdit(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-edit";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function SpecTypeChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.SpecDiscType = e.target.value;
                }
                return item;
            });
        });
    }
    function SpecMoneyChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.SpecDiscMoney = e.target.value;
                }
                return item;
            });
        });
    }
    function SpecNameChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.SpecName = e.target.value;
                }
                return item;
            });
        });
    }

    function EditContinuingStudentSet(index) {
        API_POST_ContinuingStudentSet({
            SpecName: '舊生',
            SpecDiscType: dataList[index].SpecDiscType,
            SpecDiscMoney: dataList[index].SpecDiscMoney,
            SpecMonthSet: dataList[index].SpecMonthSet,
            SpecTimesSet: dataList[index].SpecTimesSet
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    alert("修改成功");
                } else if (data.code == "2013") {
                    //特定的失敗原因
                } else {
                    setMsgStr(data.message);
                }

                //console.log(localStorage.getItem('menulist'));
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.data.code == "1001" || "") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function MonthSetChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.SpecMonthSet = e.target.value;
                }
                return item;
            });
        });
    }
    function TimesSetChg(e, idx) {
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.SpecTimesSet = e.target.value;
                }
                return item;
            });
        });
    }
    function isVisiableChange(isVisiable,idx) {
        console.log(idx);
        setDataList(function (prev) {
            return prev.map((item, i) => {
                if (i == idx) {
                    item.isVisiable = !isVisiable;
                    API_PUT_DiscVisible({
                        SpecDiscID: item.SpecDiscID,
                        isVisiable: Boolean(item.isVisiable)
                    })
                        .then((response) => response.data)
                        .then((data) => {
                            console.log(data);
                            if (data.code == "0000") {
                                Edit(0, i);
                            }  else {
                                setMsgStr(data.message);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
                
                return item;
            });
        });


    }

    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">研習之友折扣管理</h3>
                            </div>
                        </div>

                        <div className="container full">
                            <div className="info-holder">
                                <div className="left"></div>
                                <div className="right fc-1 ">
                                    <div className="btn btn-icon">
                                        <i className="icon icon-14 mr-1" onClick={type == 0 ? add : null} ></i>
                                    </div>
                                    <span className="fw-b">新增</span>
                                </div>
                            </div>
                        </div>

                        <div className="container full mt-1">
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-1">代號</div>
                                        <div className="flex-2">身份及折扣資格</div>
                                        <div className="flex-1">折扣</div>
                                        <div className="flex-1">編輯</div>
                                        <div className="flex-1">顯示</div>
                                       {/* <div className="flex-2">上傳名單</div>*/}
                                    </li>
                                    {dataList.map((x, i) => {
                                        // 如果 SpecNo 為 'A'，跳過該項目
                                        if (x.SpecNo === '8') {
                                            return null;
                                        }
                                        
                                        return(
                                        <li className="lt-row">
                                            <div className="flex-1">
                                                {/*{x.SpecIsDef == 1 ? "內建" : "新增"}*/}
                                                {x.SpecNo}
                                            </div>
                                            <div className="flex-2">
                                                {x.isEdit == true ? (
                                                    x.SpecIsDef == 1 ? x.SpecName :
                                                        <input type="text" value={x.SpecName} onChange={(e) => SpecNameChg(e, i)} />
                                                ) : (
                                                    x.SpecName
                                                )}
                                            </div>
                                            <div className="flex-1">
                                                {x.isEdit == true ? (
                                                    /*  <input type="text" value={x.discount} />*/
                                                    <div className="multi-form disabled" style={{ display: "flex" }}>
                                                        <input type="text" value={x.SpecDiscMoney} onChange={(e) => SpecMoneyChg(e, i)} style={{ width: "50%" }} />
                                                        <div className="line"></div>
                                                        <select name="unit" id="unit" style={{ width: "38%" }} value={x.SpecDiscType} onChange={(e) => SpecTypeChg(e, i)} >
                                                            <option value="" disabled selected></option>
                                                            <option value="P" >%</option>
                                                            <option value="N" >元</option>
                                                        </select>
                                                    </div>
                                                ) : (
                                                    ((x.SpecDiscMoney != null ? x.SpecDiscMoney + ((x.SpecDiscType == "P") ? "%" : "元") : ""))
                                                )}
                                            </div>
                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    {
                                                        x.SpecDiscID == null ?
                                                            //<div class="btn btn-small btn-border" onClick={(e) => Edit(2, i)}>
                                                            //    <span>確定</span>
                                                            //</div>
                                                            <div className="btn btn-icon" >
                                                                {/*<span>確定</span>*/}
                                                                <i className="icon icon-orange icon-16" onClick={(e) => Edit(2, i)}></i>
                                                                <i className="icon icon-gray icon-38" onClick={(e) => Edit(0, i)}></i>
                                                            </div>
                                                            :
                                                            x.isEdit == true ?
                                                                <div className="btn btn-icon" >
                                                                    {/*<span>確定</span>*/}
                                                                    <i className="icon icon-orange icon-16" onClick={(e) => Edit(1, i)}></i>
                                                                    <i className="icon icon-gray icon-38" onClick={(e) => Edit(0, i)}></i>
                                                                </div>

                                                                :
                                                                <div className="btn btn-icon" onClick={(e) => Edit(1, i)}>
                                                                    <i className="icon icon-24"></i>
                                                                </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    <input type="checkbox" id={"vis" + i} value={i} checked={x.isVisiable ? "checked" : ""} onChange={() => isVisiableChange(x.isVisiable, i)} />
                                                    <label htmlFor={"vis" + i}></label>
                                                </div>
                                            </div>
                                            {/*<div className="flex-2 fn">*/}

                                            {/*    {x.isUp == true ? (*/}
                                            {/*        <div className="fn-bar">*/}
                                            {/*            <div*/}
                                            {/*                className="btn btn-icon"*/}
                                            {/*            >*/}
                                            {/*                <i className="icon icon-27"></i>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    ) : (*/}
                                            {/*        <span></span>*/}
                                            {/*    )}*/}
                                            {/*</div>*/}
                                        </li>
                                        )
                                    })}
                                </ul>
                            </div>

                        </div>
                           
                          
                                <div className="container full mt-1">
                                    <div>
                                        <h5 className="mr-2">舊生原則設定：</h5>
                                </div>
                           
                            <div className="info-holder">

                                {
                                    dataList.map((x, i) => {
                                        if (x.SpecNo === '8')
                                        return (
                                        <div className="left">
                                            <div className="form-item">
                                                <span>報名時前</span>
                                                    <input type="text" value={x.SpecMonthSet} onChange={(e) => MonthSetChg(e, i)} style={{ width: "80px" }} />
                                                <span>月內完成</span>
                                                    <input type="text" value={x.SpecTimesSet} onChange={(e) => TimesSetChg(e, i)} style={{ width: "80px" }} />
                                                <span>次研習課程者，折扣</span>
                                                <div className="multi-form disabled" style={{ display: "flex" }}>
                                                        <input type="text" value={x.SpecDiscMoney} onChange={(e) => SpecMoneyChg(e, i)} style={{ width: "50%" }} />
                                                    <div className="line"></div>
                                                        <select name="unit" id="unit" style={{ width: "38%" }} value={x.SpecDiscType} onChange={(e) => SpecTypeChg(e, i)} >
                                                        <option value="" disabled selected></option>
                                                        <option value="P" >%</option>
                                                        <option value="N" >元</option>
                                                    </select>
                                                    <div className="flex-5 fc-1">
                                                        <button
                                                            className="btn btn-small btn-save"
                                                                onClick={(e) => EditContinuingStudentSet(i)}
                                                        >
                                                            儲存
                                                        </button>
                                                    </div>
                                                </div>
                                                {/*<span>，舊生身份在</span>*/}
                                                {/*<input type="text" value="" style={{ width: "80px" }} />*/}
                                                {/*<span>月內有效。</span>*/}
                                            </div>
                                        </div>

                                        )
                                    })

                                    }
                                        <div className="right fc-1">
                                            <div className="btn btn-icon">
                                                <i className="icon icon-27 mr-1" ></i>
                                            </div>
                                            <span className="fw-b">上傳舊生檔案</span>
                                        </div>
                                    </div>
                             
                                    <div>
                                        <h5 className="mr-2">舊生計算公式：舉例報名前12月內完成2次研習課程，舊生身分24個月有效。</h5>
                                    </div>
                                    <img src={require("../../images/discount.png")}></img>
                                </div>
                            
                       
                    </div>
                </div>
            </div>
        </>
    );
}
export default TraineeDiscount;
