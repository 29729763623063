import axios from 'axios'; //�s��api
import { getAuthToken } from './TokenUtil';

export const getHeaders = (token) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': token
    }
}
//�s��API URL
//export const API_HOST = "https://localhost:7021/api";
export const API_HOST = window.apiUrl;


//�@�뱡�p��
const baseRequest = axios.create({
    baseURL: API_HOST
}); 
//�U����
export const downloadRequest = axios.create({
    baseURL: API_HOST,
    responseType: 'blob'
});


//#region �@��api
export const apiLangSetting = data => baseRequest.get('/languageData');
export const apiAreaSetting = data => baseRequest.get('/areaData');
//#endregion

//#region �D�e��
//�D�e��-���oMENU
export const API_POST_Menus = data => baseRequest.post('/Menu/GetMenus', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//#endregion

//#region �\��-A
//0-0 ���ͨϥΪ̱b��
export const API_POST_AddCourseType = data => baseRequest.post('/Course/CourseTypeCData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) }); 

//0.1.0 �t�κ޲z��-�b���C��
export const API_Get_AdminHome = data => baseRequest.get('/Admin/Home', { params: data, headers: getHeaders(getAuthToken()) });

//0.1.1 �t�κ޲z��-�b���s�W
export const API_POST_AddCreateUser = data => baseRequest.post('/Account/CreateUser', JSON.stringify(data), { headers: getHeaders(getAuthToken()) }); 

//0.1.2 �t�κ޲z��-�b���R��
export const API_DELETE_DeleteAcnt = data => baseRequest.post('/Account/DeleteAcnt', JSON.stringify(data), { headers: getHeaders(getAuthToken()) }); 

//0.1.3 �t�κ޲z��-�b�����m�K�X
export const API_POST_ResetPwd = data => baseRequest.post('/Account/ResetPwd', JSON.stringify(data), { headers: getHeaders(getAuthToken()) }); 

//0.1.4 �t�κ޲z��-�b������/��_�v��
export const API_POST_InvalidAcnt = data => baseRequest.post('/Account/InvalidAcnt', JSON.stringify(data), { headers: getHeaders(getAuthToken()) }); 

//0.1.5 �t�κ޲z��-�ܧ�K�X (�޲z���ا��b�� �p�G�ϥΪ̬O�Ĥ@���n�J �h�޾ɥL�ۦ�ק�K�X)  66666666666666
export const API_POST_UpdateUserPwd = data => baseRequest.post('/Account/UpdateUserPwd', JSON.stringify(data), { headers: getHeaders(getAuthToken()) }); 

//0.2.0 ���޲z��-�b���C��
export const API_POST_HomeCenter = data => baseRequest.post('/Admin/HomeCenter', JSON.stringify(data), { headers: getHeaders(getAuthToken()) }); 


//0-3 ���m�K�X
//export const API_Account_ResetPWD = data => baseRequest.get('/authenticate/resetpwd', { params: data, headers: getHeaders(getAuthToken()) });
//export const API_PUT_ProductReview = data => baseRequest.put('/product/review', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//0-4 �C�X�ϥΪ��v��-------------------- 
export const API_GET_FuncList = data => baseRequest.get('/Account/GetFuncList', { params: data, headers: getHeaders(getAuthToken()) });
export const API_Put_FuncList = data => baseRequest.put('/Account/PutFuncList', JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }  });
//0-5 �ϥΪ̵n�J
export const API_Login = data => baseRequest.post('/Login/SinginAdmin', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*' } 
});
//1.1.0-�����޲z-�����s�i�]�w-�����C��
export const API_GET_GetCarouselList = data => baseRequest.get('/Home/GetCarouselList', { params: data, headers: getHeaders(getAuthToken()) });

//1.1.1-�����޲z-�����s�i�]�w-�s�W����
export const API_POST_CarouselInfo = data => baseRequest.post('/Home/CarouselInfo', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//1.1.2-�����޲z-�����s�i�]�w-�s�����
export const API_PUT_CarouselInfo = data => baseRequest.put('/Home/CarouselInfo', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//1.1.3-�����޲z-�����s�i�]�w-�ܧ�������
export const API_POST_UpdateHome = data => baseRequest.put('/Home/UpdateHome', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//1.1.4-�����޲z-�����s�i�]�w-�R������
export const API_DELETE_delData = data => baseRequest.delete('/Home/CarouselInfo',  { params: data, headers: getHeaders(getAuthToken()) });

//1.1.5-�����޲z-�����s�i�]�w-�����Ƨ�(���W)
export const API_POST_CarouselSortUp = data => baseRequest.post('/Home/CarouselSortUp', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//1.1.6-�����޲z-�����s�i�]�w-�����Ƨ�(���U)
export const API_POST_CarouselSortDown = data => baseRequest.post('/Home/CarouselSortDown', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//1.2.0-�����޲z-���i�޲z-���i�C��
export const API_GET_GetAnnouncementList = data => baseRequest.get('/Announcement/GetAnnouncementList', { params: data, headers: getHeaders(getAuthToken()) });

//1.2.1-�����޲z-���i�޲z-�s�W���i
export const API_POST_AnnouncementInfo = data => baseRequest.post('/Announcement/AnnouncementInfo', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//1.2.1-�����޲z-���i�޲z-�s�W���i
export const API_POST_AnnouncementList = data => baseRequest.get('/Front/AnnouncementList', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//1.2.2-�����޲z-���i�޲z-�s�褽�i
export const API_PUT_AnnouncementInfo = data => baseRequest.put('/Announcement/AnnouncementInfo', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//1.2.3-�����޲z-���i�޲z-�ܧ����
export const API_POST_UpdateOpen = data => baseRequest.post('/Announcement/UpdateOpen', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//1.2.4-�����޲z-���i�޲z-�R�����i
export const API_DELETE_AnnouncementInfo = data => baseRequest.delete('/Announcement/AnnouncementInfo', { data: data, headers: getHeaders(getAuthToken()) });

//1.2.5-�����޲z-���i�޲z-�ƧǤ��i(���W)
export const API_POST_AnnouncementSortUp = data => baseRequest.post('/Announcement/AnnouncementSortUp', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//1.2.6-�����޲z-���i�޲z-�ƧǤ��i(���U)
export const API_POST_AnnouncementSortDown = data => baseRequest.post('/Announcement/AnnouncementSortDown', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//2.2.0 ú�O�P�_
export const API_PUT_isPay = data => baseRequest.put('/Course/isPay', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});

//2.2.0 �ҵ{���O�d��
export const API_CourseTypeCData = data => baseRequest.get('/Course/CourseTypeCData', { params: data, headers: getHeaders(getAuthToken()) });

//2.2.0 �ҵ{���O���
export const API_Put_CourseType = data => baseRequest.put('/Course/CourseTypeCData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//2.1.0 �ҵ{���O�d��
export const API_CoursePeriodData = data => baseRequest.get('/Course/CoursePeriodData', { params: data, headers: getHeaders(getAuthToken()) });
//2.1.1 �ҵ{���O�s�W
export const API_POST_CoursePeriodData = data => baseRequest.post('/Course/CoursePeriodData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//2.1.2 �ҵ{���O���
export const API_Put_CoursePeriodData = data => baseRequest.put('/Course/CoursePeriodData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//2.1.3 �ҵ{���O�R��
export const API_Del_CoursePeriodData = data => baseRequest.delete('/Course/CoursePeriodData', { data: data, headers: getHeaders(getAuthToken()) });

//2.1.6 - ��O�ҵ{�޲z - �ҵ{���O�Ҫ��U��
export const API_POST_ExCourse = data => downloadRequest.post('/Course/ExCourse', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//2.5.1-��O�ҵ{�޲z-�ҵ{�L�{�޲z-�I�W��
export const API_POST_ExClass = data => downloadRequest.post('/Course/ExClass', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });
//2.5.2-��O�ҵ{�޲z-�ҵ{�L�{�޲z-�I�W��
export const API_POST_ExSClass = data => downloadRequest.post('/Course/ExSClass', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });


//2.5.1-��O�ҵ{�޲z-�ҵ{�L�{�޲z-�ǭ�����
export const API_POST_ExCouStud = data => downloadRequest.post('/Course/ExCouStud', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//2.5.3-��O�ҵ{�޲z-�ҵ{�L�{�޲z-�q�����W��
export const API_POST_ExCouLottery = data => downloadRequest.post('/Course/ExCouLottery', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//2.7.0 
export const API_Get_TeacherBaseData = data => baseRequest.get('/Course/TeacherBaseData', { params: data, headers: getHeaders(getAuthToken()) });
//2.7.1
export const API_POST_AddTeacherData = data => baseRequest.post('Course/TeacherBaseData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//2.7.2
export const API_Put_TeacherBaseData = data => baseRequest.put('/Course/TeacherBaseData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});


//2.0.0-��O�ҵ{�޲z-�U�Ԧ����
export const API_Get_DropDown = data => baseRequest.get('/Course/DropDown', { params: data, headers: getHeaders(getAuthToken()) });
//2.0.0-��O�ҵ{�޲z-�馩
export const API_Get_SpecList = data => baseRequest.get('/Course/SpecList', { params: data, headers: getHeaders(getAuthToken()) });
//2.0.1-��O�ҵ{�޲z-�ҵ{�馩and����
export const API_Get_CourseSpecAndAtt = data => baseRequest.get('/Course/CourseSpecAndAtt', { params: data, headers: getHeaders(getAuthToken()) });
//2.4.0-��O�ҵ{�޲z-�ҵ{���e�޲z�d��Basepost
export const API_Get_CourseBaseData = data => baseRequest.post('/Course/Basepost', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//2.4.1-��O�ҵ{�޲z-�ҵ{���e�޲z�s�W
export const API_POST_CourseBaseData = data => baseRequest.post('/Course/CourseBaseData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//2.4.2-��O�ҵ{�޲z-�ҵ{���e�޲z�s��d��
export const API_POST_VBaseget = data => baseRequest.post('/Course/VBaseget', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//2.4.2-��O�ҵ{�޲z-�ҵ{���e�޲z�s��
export const API_Put_CourseBaseData = data => baseRequest.put('/Course/CourseBaseData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//2.4.3-��O�ҵ{�޲z-�ҵ{���e�޲z�R��
export const API_Del_CourseBaseData = data => baseRequest.delete('/Course/CourseBaseData', { data: data, headers: getHeaders(getAuthToken()) });

//2.61 �ҵ{���W��C���d��
export const API_Get_Lotterypost = data => baseRequest.post('/Course/Lotterypost', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//2.6.2 �ҵ{���W���x�s
export const API_Put_LotterySave = data => baseRequest.put('/Course/LotterySave', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//2.6.3 �d�ݩ��W��(�Ϥ�)
export const API_Get_LotteryList = data => baseRequest.get('/Course/LotteryList', { params: data, headers: getHeaders(getAuthToken()) });

//2.6.4 �ҵ{�޲z-���W��H��
export const API_Get_LotteryName = data => baseRequest.get('/Course/LotteryName', { params: data, headers: getHeaders(getAuthToken()) });

//2.6.5 �ҵ{���-���
export const API_Get_Lottery = data => baseRequest.get('/Course/Lottery', { params: data, headers: getHeaders(getAuthToken()) });

//2.6.6 �ҵ{�޲z-�q���W����-�妸���
export const API_Get_LotteryBatch = data => baseRequest.get('/Course/LotteryBatch', { params: data, headers: getHeaders(getAuthToken()) });

//2.6.1 �ҵ{���
export const API_POST_LotteryInt = data => baseRequest.post('/Course/LotteryInt', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//2.3.0-��O�ҵ{�޲z-���W�@�~�ɶ��d��
export const API_Get_CourseSchdData = data => baseRequest.get('/Course/CourseSchdData', { params: data, headers: getHeaders(getAuthToken()) });

//2.3.1-��O�ҵ{�޲z-���W�@�~�ɶ��s�W
export const API_POST_CourseSchdData = data => baseRequest.post('/Course/CourseSchdData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2.3.2-��O�ҵ{�޲z-���W�@�~�ɶ��s��d��
export const API_POST_ViewSchdget = data => baseRequest.post('/Course/ViewSchdget', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2.3.3-��O�ҵ{�޲z-���W�@�~�ɶ��R��
export const API_Put_CourseSchdData = data => baseRequest.put('/Course/CourseSchdData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
})
//3.0.0-��ߤ��ͧ馩�޲z-�d��
export const API_GET_SpecDiscountData = data => baseRequest.get('/Discount/SpecDiscountData', { headers: getHeaders(getAuthToken()) });
//3.0.1-��ߤ��ͧ馩�޲z-�s�W
export const API_POST_SpecDiscountData = data => baseRequest.post('/Discount/SpecDiscountData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }});
//3.0.2-��ߤ��ͧ馩�޲z-�s��
export const API_PUT_SpecDiscountData = data => baseRequest.put('/Discount/SpecDiscountData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//3.0.1-��ߤ��ͧ馩�޲z-�s�W
export const API_POST_ContinuingStudentSet = data => baseRequest.post('/Discount/ContinuingStudentSet', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//3.0.3-��ߤ��ͧ馩�޲z-����ܧ�
export const API_PUT_DiscVisible = data => baseRequest.put('/Discount/DiscVisible', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//3.0.1-��ߤ��ͨ����n�O-�D����
export const API_GET_DiscountHome = data => baseRequest.get('/Discount/Home', { params: data, headers: getHeaders(getAuthToken()) });
//3.0.0-��ߤ��ͨ����n�O-�d��
export const API_GET_DiscountInfo = data => baseRequest.get('/Discount/DiscountInfo', { params: data, headers: getHeaders(getAuthToken()) });
//3.0.2-��ߤ��ͨ����n�O-�s��
export const API_PUT_DiscountInfo = data => baseRequest.put('/Discount/DiscountInfo', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});

//3.1.0-��ߤ���-�d��
export const API_POST_StudyInFo = data => baseRequest.post('/Study/StudyInFo', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//3.1.1-��ߤ���-�s�W
export const API_POST_AddStudy = data => baseRequest.post('/Study/AddStudy', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//3.1.2-��ߤ���-�s��
export const API_Put_EditStudy = data => baseRequest.put('/Study/EditStudy', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
})

//3.1.2-��ߤ���-�˵�
export const API_POST_ViewStudy = data => baseRequest.post('/Study/ViewStudy', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//7.0.1�ǭ��q���޲z-�U�Ԧ����
export const API_Get_NoticeItem = data => baseRequest.get('/Notice/NoticeItem', { params: data, headers: getHeaders(getAuthToken()) });

//7.0.2�ǭ��q���޲z-�ҵ{�N�X�U�Ԧ����
export const API_Put_CouNoget = data => baseRequest.put('/Notice/CouNoget', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
})
//7.1.0�ǭ��q���޲z-�o�e�q���򥻳]�m�d��
export const API_Get_DeptNoticeData = data => baseRequest.get('/Notice/DeptNoticeData', { params: data, headers: getHeaders(getAuthToken()) });

//7.1.1�ǭ��q���޲z-�o�e�q���򥻳]�m�s��
export const API_Put_DeptNoticeData = data => baseRequest.put('/Notice/DeptNoticeData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
})

//7.1.2�ǭ��q���޲z-�򥻳q���ҪO�]�m�d��
export const API_Get_NoticeSetDataS = data => baseRequest.get('/Notice/NoticeSetData?sNoticeType=S', { params: data, headers: getHeaders(getAuthToken()) });

//7.1.3�ǭ��q���޲z-�򥻳q���ҪO�]�m�s��
export const API_Put_NoticeSetSput = data => baseRequest.put('/Notice/NoticeSetSput', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
})

//7.1.4�ǭ��q���޲z-�򥻳q���ҪO�]�m����
export const API_Post_DeptNoticeTest = data => baseRequest.post('/Notice/DeptNoticeTest', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//7.2.0�ǭ��q���޲z-�@��q���ҪO�]�m�d��
export const API_Get_NoticeSetDataG = data => baseRequest.get('/Notice/NoticeSetData?sNoticeType=G', { params: data, headers: getHeaders(getAuthToken()) });

//7.2.1�ǭ��q���޲z-�@��q���ҪO�]�m�s��d��
export const API_Post_NoticeSetG_Mget = data => baseRequest.post('/Notice/NoticeSetG_Mget', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//7.2.2�ǭ��q���޲z-�@��q���ҪO�]�m�s�W_�x�s�ҪO
export const API_Post_NoticeSetGpost = data => baseRequest.post('/Notice/NoticeSetGpost', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//7.2.3�ǭ��q���޲z-�@��q���ҪO�]�m�R��
export const API_Put_NoticeSetG_Dput = data => baseRequest.put('/Notice/NoticeSetG_Dput', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
})

//7.2.4�ǭ��q���޲z-�@��q���ҪO�]�m�s��_�x�s�ҪO
export const API_Put_NoticeSetG_Mput = data => baseRequest.put('/Notice/NoticeSetG_Mput', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
})

//7.2.5�ǭ��q���޲z-�q���Ƶ{�d��
export const API_Get_NoticeSendData = data => baseRequest.get('/Notice/NoticeSendData', { params: data, headers: getHeaders(getAuthToken()) });

//7.2.6�ǭ��q���޲z-�q���Ƶ{�R��
export const API_Put_NoticeSend_Dput = data => baseRequest.put('/Notice/NoticeSend_Dput', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
})

//7.2.7�ǭ��q���޲z-�@��q���T�w
export const API_Post_NoticeSendpost = data => baseRequest.post('/Notice/NoticeSendpost', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//7.2.8�ǭ��q���޲z-�d�ߵo�e�H��
export const API_Post_GetSendNumber = data => baseRequest.post('/Notice/GetSendNumber', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//7.2.9�ǭ��q���޲z-�@��q������
export const API_Post_NoticeSendTest = data => baseRequest.post('/Notice/NoticeSendTest', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//7.3.1�ǭ��q���޲z-�q���o�e���G�έp�d��
export const API_Post_SendRecget = data => baseRequest.post('/Notice/SendRecget', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//7.3.2�ǭ��q���޲z-�q���o�e���G�έp�d��
export const API_Post_SendRecCheckData = data => baseRequest.post('/Notice/SendRecCheckData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


//��O�h�O�ӽк޲z-�h�O�ӽЬd��
export const API_Get_RefoundData = data => baseRequest.get('/Refund/RefundData', { params: data, headers: getHeaders(getAuthToken()) });

//��O�h�O�ӽк޲z-�h�O�ӽнs��d��
export const API_Get_RefundDataget = data => baseRequest.get('/Refund/RefundDataget', { params: data, headers: getHeaders(getAuthToken()) });

//��O�h�O�ӽк޲z-�h�O�ӽнs��
export const API_Put_RefundData = data => baseRequest.put('/Refund/RefundData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
})

//��O�h�O�ӽк޲z-�U���h�O����
export const API_Post_RefundRpt = data => downloadRequest.post('/Refund/RefundRpt', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//�ҵ{�Z�O�d��
export const CourseTypeData = data => baseRequest.get('/Course/CourseTypeData', { params: data, headers: getHeaders(getAuthToken()) });

//�ҵ{�Z�O�s�W
export const API_POST_CourseTypeData = data => baseRequest.post('/Course/CourseTypeData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�ҵ{�Z�O�s��
export const API_Put_CourseTypeData = data => baseRequest.put('/Course/CourseTypeData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});

//��O�{��ú�O�h�O-�{��ú�O�h�O�d��
export const API_Get_PayRefundDataget = data => baseRequest.get('/Refund/PayRefundDataget', { params: data, headers: getHeaders(getAuthToken()) });

//��O�{��ú�O�h�O-�{���h�O
export const API_Put_ReFundput = data => baseRequest.put('/Refund/ReFundput', JSON.stringify(data), {
   params:data, headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});

//�h�O�T�{ 1120727 JK:�N�e�x���ӤH�h�O ��x�]���@��
export const API_BUserRefund = data => baseRequest.post('/Front/BUserRefundpost', JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() } });
//�ҵ{����(�h�O����) 1120727 JK:�P�W
export const API_URegDetail = data => baseRequest.get('/Front/URegDetail', { params: data, headers: getHeaders(getAuthToken()) });

export const API_BackURegDetail = data => baseRequest.get('/Front/BackURegDetail', { params: data, headers: getHeaders(getAuthToken()) });

//��O�{��ú�O�h�O-�{��ú�O
export const API_Put_Payput = data => baseRequest.put('/Refund/Payput', JSON.stringify(data), {
   params:data, headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});

//��x�{��ú�O/�h�O ����
export const API_Get_ReceiptPDF = data => downloadRequest.get('/Front/ReceiptPDF', { params: data, headers: getHeaders(getAuthToken()) });
//��x�U���ɮ�
export const API_Get_Download = path => downloadRequest.get(path, { headers: getHeaders(getAuthToken()) });

//2.4.4-��O�ҵ{�޲z-�ҵ{���e�޲z�ƻs�\��-�d��
export const API_POST_CopyCourseget = data => baseRequest.post('/Course/CopyCourseget', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//2.4.4-��O�ҵ{�޲z-�ҵ{���e�޲z�ƻs�\��-�s��
export const API_Post_CopyCoursePost = data => baseRequest.post('/Course/CopyCoursePost', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//�ݨ����@ 4.0.1
export const API_QNRList = data => baseRequest.get('/QNR/QNRList', { params: data, headers: getHeaders(getAuthToken()) });
//�ݨ����@ 4.0.2
export const API_QNRResult = data => baseRequest.get('/QNR/QNRResult', { params: data, headers: getHeaders(getAuthToken()) });
//�ݨ����@ 4.1.1
export const API_QunData = data => baseRequest.get('/QNR/QNRQunData', { params: data, headers: getHeaders(getAuthToken()) });
//�ݨ����@ 4.1.2
export const API_Post_QunData = data => baseRequest.post('/QNR/QNRQunData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//�ݨ����@ 4.1.3
export const API_Put_QunData = data => baseRequest.put('/QNR/QNRQunData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//�ݨ����@ 4.1.4
export const API_Del_QunData = data => baseRequest.delete('/QNR/QNRQunData', {
    params:data,
    headers: { 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//�ݨ����@ 4.1.5
export const API_Put_QunDataChoice = data => baseRequest.put('/QNR/QunChoiceData', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});

//2.5.0-��O�ҵ{�޲z-�ҵ{�L�{�޲z-�d��
export const API_POST_Coursepost = data => baseRequest.post('/Course/Coursepost', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});

//�{�����W-�ҵ{�Ӹ`
export const API_CourseDtail = data => baseRequest.get('/Front/BackCourseDetail', { params: data, headers: getHeaders(getAuthToken()) });

//��ߤ��͸�ƺ��@-�{�����W-�d�߬�ߤ���
export const API_GetStudyInfo = data => baseRequest.get('/Study/GetStudyInfo', { params: data, headers: getHeaders(getAuthToken()) });

//��ߤ��͸�ƺ��@-�{�����W-�T�{���W
export const API_POST_CourseReg = data => baseRequest.post('/Front/CourseReg', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//��ߤ��͸�ƺ��@-�{�����W-�T�{���W
export const API_POST_BackCourseReg = data => baseRequest.post('/Front/BackCourseReg', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//5.0.1-��O�ҵ{�޲z-�~�Ȳέp����-�d��
export const API_POST_SaleRptpost = data => baseRequest.post('/SaleRpt/SaleRptpost', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//5.0.2 - ��O�ҵ{�޲z - �~�Ȳέp�����U��
export const API_POST_SaleRpt = data => downloadRequest.post('/SaleRpt/SaleRpt', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//5.0.3-��O�ҵ{�޲z-�~�Ȳέp����-��ҵ{�U��
export const API_POST_SaleRptRow = data => downloadRequest.post('/SaleRpt/SaleRptRaw', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//5.0.1 - ��O�ҵ{�޲z - �]�Ȳέp���� - �d��
export const API_POST_FinRptpost = data => baseRequest.post('/FinRpt/FinRptpost', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//5.0.2-��O�ҵ{�޲z-�]�Ȳέp�����U��
export const API_POST_FinRpt = data => downloadRequest.post('/FinRpt/FinRpt', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//5.0.3 - ��O�ҵ{�޲z - �U����b�� 
export const API_POST_AllFinRpt = data => downloadRequest.post('/FinRpt/AllFinRpt', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//��O�έp����-�C����W�H��
export const API_GET_DailyRpt = data => baseRequest.get('/Report/DailyReport', { params: data, headers: getHeaders(getAuthToken()) });
